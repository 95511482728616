import axios from "../axios";
import placeholder from "../img/imagePlaceholder.png"
import avatarPlaceholder from "../img/avatar_placeholder.png"
import { getAvatarsArr } from "./generalConnections";


export const checkPassword = async (event_hash, password, telegram_id) => {
    try {
        const {data} = await axios.post(`/api/check_password/`, {
            event_hash: event_hash, 
            password: password,
            telegram_id: telegram_id
        })
        if (data.is_equal === true) {
            return null
        }
        if (data.is_equal === false) {
            return "wrong"
        }
        switch (data.status) {
            case "NOT_FOUND":
                return "error"
        
            default:
                return "error";
        }
    } catch (error) {
        console.log(error)
        return "error";
    }
}





const getParams = async (event_hash) => {
    console.log("getParams event_hash", event_hash)
    try {
        const req = {
            event_hash: event_hash
        }
        const response = await axios.post(`/api/event_info/`, req)
        const data = response.data.event_info
        const status = response.data.status
        console.log("getParams status", status, `status === "NOT_FOUND"`, status === "NOT_FOUND")
        if (status === "NOT_FOUND") {
            return {
                status: "Event not found"
            }
        }
        if (status === "DB_READING_ERROR") {
            return {
                status: "Server Error"
            }
           
        }
        var channels_array = null
        if (typeof data.subscriptions === "string") {
            channels_array = data.subscriptions.split(',').filter(item => item)
        }
        else {
            if (Array.isArray(data.subscriptions)) {
                channels_array = data.subscriptions
            }            
            
        }
       
        console.log("getParams data", data)
        return {
            start_date: data.start_date,
            end_date: data.end_date,
            invites: data.invites,
            event_name: data.event_name,
            subscriptions: channels_array,
            minted_nfts: data.minted_nfts,
            nfts_cnt: data.nfts_cnt,
            logo: data.event_logo_url,
            description: data.description,
            collection_name: data.collection_name,
            empty_password: data.empty_password,
            user_timezone: data.user_timezone,
            is_link_used: data.is_link_used
        }
    } catch (error) {
        console.log(error)

        if (error.response && error.response.data) {
            const status = error.response.data.status;
            if (status === "NOT_FOUND") {
                return {
                    status: "Event not found"
                };
            }
        }

        return {
            status: "Server Error"
        };
      
        // return {
        //     collection_name: "Collection Name",
        //     event_name: "Event Name",
        //     description: "This is my first event.This is my first event. This is my first event.",
        //     logo: placeholder,
        //     start_date: `2024-12-21T13:17`,
        //     end_date: `2024-12-27T13:18`,
        //     invites: 0,
        //     subscriptions: ["@rasfocuse", "@FarmerDao", "@channel1"],
        //     subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
        //     minted_nfts: 9,
        //     nfts_cnt: 9,
        //     empty_password: true,
        //     user_timezone: 3
        
        // }
        
    }
}


export const setInitialParams = async (event_hash, {setCollectionParams, setEventStatus, setIsGetInactive, addError, setNftStatus}) => {
    try {
        console.log("setInitialParams event_hash", event_hash)
        const params = await getParams(event_hash);
        //console.log("params", params)
        if (params.status) {
            setEventStatus(params.status)
            return null
        }
       
        const avatars_arr = await getAvatarsArr(params.subscriptions);
        setCollectionParams(prevValues => ({
            ...prevValues, 
            ...params,
            subscriptions_avatar_urls: avatars_arr
        })); 
     
        if (params.is_link_used) {
            setIsGetInactive(true)
            setNftStatus(true)
            addError("nft", "Link has been fully used", "error")
        }
       // console.log("params.minted_nfts, params.nfts_cnt", params.minted_nfts, params.nfts_cnt)
       
        //setErrorsMap
        const date = new Date()
        if (params.start_date > date || params.end_date < date )
            {
               
                setIsGetInactive(true)
            }

       
        // if (params.minted_nfts >= params.nfts_cnt) {
        //     addError("nft", "All the NFTs from this event have already been distributed", "error")
        //     setIsGetInactive(true)
        //     setNftStatus(true)
        // }
        
       
        
        return true
    } catch (error) {
        // setCollectionParams ({
        //     collection_name: collection_name,
        //     start_date: `2024-08-30T00:00`,
        //     end_date: `2024-09-07T00:00`,
        //     invites: 5,
        //     subscriptions: ["@channel1", "@channel2", "@channel3"],
        //     logo: placeholder,
        //     description: "Collection Description",
        //     subscriptions_avatar_urls: [placeholder, placeholder, placeholder]

        // })

        setCollectionParams({
            collection_name: "Collection Name",
            event_name: "Event Name",
            description: "This is my first event.This is my first event. This is my first event.",
            logo: "",
            start_date: "",
            end_date: "",
            invites: 0,
            subscriptions: ["@rasfocuse", "@FarmerDao"],
            subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
            minted_nfts: 9,
            nfts_cnt: 9
        })
        // setCollectionParams({
        //     collection_name: "",
        //     description: "",
        //     logo: "",
        //     start_date: "",
        //     end_date: "",
        //     invites: 0,
        //     subscriptions: ["@rasfocus", "@FarmerDao", "@channel1"],
        //     subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
        //     minted_nfts: 1,
        // })
        console.log(error)
        setEventStatus("Server Error")
        return null
    }
}

export const sendDataToServer = async (send_json) => {
    try {
        const {data} = await axios.post(`/api/send_nft/`, send_json)
        if (data.status) {
            return data.status
        }
        else {
            return "error"
        }

       
    } catch (error) {
         if (error.response && error.response.data) {
            const status = error.response.data.status;
            console.log(status)
            return status
        }
        console.log(error)
        return "error"
    }
}

// 2) При запросе на /checker/user_info/ по ключу "user_info" теперь возвращается в словаре дополнительный ключ "participated_events" - список с id событий, в которых участвовал пользователь. Если id события текущей страницы будет присутствовать в  participated_events, то нужно показать всплывашку с текстом: "You have already received the nft from this event", а также сделать кнопку get неактивной 

export const getUserInfo = async (telegram_id, username, event_hash) => {
    console.log("getUserInfo telegram_id, username, event_hash", telegram_id, username, event_hash)
    const sending_json = {
        telegram_id: telegram_id,
        username: username,
        event_hash: event_hash
    }
    try {
        const {data} = await axios.post(`/api/user_info/`, sending_json)
        if (data.status === "success") {
            return data.user_info
        }
        console.log("Error:",data.description);
        
        return null
       
    } catch (error) {        
        console.log(error)
        return null
        
    }

}

export const addVisitedChannel = async (telegram_id, channel) => {
    const sending_json = {
        telegram_id: telegram_id,
        channel: channel
    }
    try {
        const {data} = await axios.post(`/api/add_visited_channel/`, sending_json)
        if (data.status === "success") {
            return true
        }
        return false

       
    } catch (error) {
        console.log(error)
        return false
    }

}

export const IsUserSubscribed = async (telegram_id, channel) => {
    const sending_json = {
        telegram_id: telegram_id,
        channel: channel
    }
    try {
        const {data} = await axios.post(`/api/is_user_subscribed/`, sending_json)
        return data

       
    } catch (error) {
        console.log(error)
        return null
    }

}



