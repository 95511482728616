import { useEffect, useState } from "react"
import expandArrow from '../img/expandArrow.svg'
import collapseArrow from '../img/collapseArrow.svg'
import './Statistics.css'
import { Link, useNavigate } from "react-router-dom"
import { getStatisticsData } from "../utils/statisticsConnections"

const calcLineWidth = (count, count_all) => {
    if (count>=count_all) {
        return "100%"
    }
    const part = count/count_all
    return `${(100*part).toString()}%`

}

const EventElement = ({name, index, nfts_count, nft_amount, event_hash}) => {

    const [isOpen, setIsOpen] = useState(false)

    const PORT = process.env.REACT_APP_API_URL.split(":")[2] ? `:${process.env.REACT_APP_API_URL.split(":")[2]}` : ""
    const BOT = PORT === ":8080" ? "Ton_Application_Bot" : "LidumAppBot"
    const APP_NAME = PORT === ":8080" ? "lidum_test" : "app"

    const link = `/minter?event_hash=${event_hash}`
    const linkFormatted = `https://t.me/${BOT}...${event_hash.slice(-4)}`

    const arrowClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="statistics-event-container" key={`event-${index}`}>
        <div className="first-string">
            <p className="statistics-event-label">{name}</p>
            <img src={isOpen ? collapseArrow : expandArrow} className={"open-icon"} onClick={arrowClick}/>
        </div>
        
        {isOpen && (
            <div className="statistics-content">
                <div className={`statistics-event-nft-indicator ${nfts_count === 0 ? "" : "active"}`}>
                    <div className="statistics-indicator-field">
                        <span className="text">NFT were given</span>
                        <span className="ratio-background" style={{width: calcLineWidth(nfts_count, nft_amount)}}></span>
                    </div>
                    <p className="ratio-text">{nfts_count}/{nft_amount}</p>
                </div>
                <p className="event-status">{nfts_count < nft_amount ? "Ongoing" : "Completed"}</p>
                <Link className="event-link" to={event_hash ? link : "#"}>{linkFormatted}</Link>

            </div>
        )

        }
        
        
    </div>
    )

    
}

export const Statistics = ({setIsBackButtonShow, setIfNotSavingData, telegramId, username}) => {

    const navigate = useNavigate()

    const [eventsList, setEventsList] = useState([])
    
    


    const onBackButtonClick = () => {
        navigate(-1)
      }

    useEffect(() => {
        const handleBackButton = () => {
            navigate(-1);
        };

        setIfNotSavingData(false);
        setIsBackButtonShow(true);
        
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButton);
        }
        
        return () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButton);
            }
        };
    }, [navigate]);

    useEffect( () => {
        const getStatisticsDataHandle = async (telegramId, username) => {
            const statistics_data = await getStatisticsData(telegramId, username) 
            if (statistics_data === null) {
                setEventsList([{
                    name: "Event 1",
                    nfts_count: 0,
                    nft_amount: 5,
                    event_hash: `sdfsdfOVecQEnBYbXsdfsdfDAGtgL-sdfsdf`
                }, {
                    name: "Event 2",
                    nfts_count: 5,
                    nft_amount: 10,
                    event_hash: `werwerwersdfsdfDAGtgL-sdfsdfwerwer`
                }, {
                    name: "Event 3",
                    nfts_count: 20,
                    nft_amount: 20,
                    event_hash: `sddfsdffdyutyutyhjghjg-hgjghjghjgj`
                },])
                return 
            }
            // "events_info": [ {"minted_nfts", "nfts_cnt", "event_name", "link_hash"},
            const newEventsList = []
            for (const event of statistics_data) {
                newEventsList.push({
                    name: event.event_name,
                    nfts_count: event.minted_nfts,
                    nft_amount: event.nfts_cnt,
                    event_hash: event.link_hash
                });
            }
            setEventsList(newEventsList)
        }
        getStatisticsDataHandle(telegramId, username)
    }, [telegramId, username])


    return (
        <div className="statistics-container">
            {eventsList.map((event, index) => (
                <>
                    <EventElement name={event.name} index={index} nfts_count={event.nfts_count} nft_amount={event.nft_amount} event_hash={event.event_hash} />
                </>
                
            ))}
        </div>
    )
}