import "./Onboarding.css"
import firstSlide from "../img/onboarding/1.gif"
import secondSlide from "../img/onboarding/2.gif"
import thirdSlide from "../img/onboarding/3.gif"
import fourthSlide from "../img/onboarding/4.gif"
import fifthSlide from "../img/onboarding/5.gif"
import whiteArrowBack from "../img/whiteArrowBack.svg"
import whiteArrowNext from "../img/whiteArrowNext.svg"
import React, { useEffect, useRef, useState } from "react"

import firstVideo from "../videos/onboarding/1.mp4"
import secondVideo from "../videos/onboarding/2.mp4"
import thirdVideo from "../videos/onboarding/3.mp4"
import fourthVideo from "../videos/onboarding/4.mp4"
import fifthVideo from "../videos/onboarding/5.mp4"


const VideoComponent = React.memo(({ src, isPlaying, onLoadedMetadata, onTimeUpdate, onEnded }) => {
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const videoRatio = 2.026
    useEffect(() => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
        
    }, [isPlaying, src]);

    return (
        <div className="video-container" ref={containerRef}>
            <video 
                ref={videoRef} 
                loop 
                muted 
                playsInline 
                webkit-playsinline="true"
                x5-playsinline="true"
                className="onboarding-image" 
                onTimeUpdate={onTimeUpdate} 
                onEnded={onEnded} 
                onLoadedMetadata={(e) => {
                    onLoadedMetadata(e.target);
                    // if (containerRef.current) {
                    //     containerRef.current.style.width = `${containerRef.current.offsetHeight / videoRatio}px`;
                    // }
                }}
            >
                <source src={src} type="video/mp4" />
                Ваш браузер не поддерживает видео.
            </video>
        </div>
    );
});

export const Onboarding = ({isOpen, onClose}) => {


    const [isPlaying, setIsPlaying] = useState(true)
    const gifArr = [firstSlide, secondSlide, thirdSlide, fourthSlide, fifthSlide]
    const gifLabels = ["Let’s connect your wallet", "How to make a wallet", "Link your email", "Claim NFT", "Your NFT in TON Space"]
    const [gifNumber, setGifNumber] = useState(0)
    const [videoDuration, setVideoDuration] = useState(0);
    const timeArr = [3.0, 15.28, 16.16, 8.24, 7.0]
   
    const [isAnimating, setIsAnimating] = useState(false);

    const videosArr = [firstVideo, secondVideo, thirdVideo, fourthVideo, fifthVideo]
    const [videoSrc, setVideoSrc] = useState(videosArr[0]);
    
    const [progress, setProgress] = useState(Array(5).fill(0));
   
   

    const backClick = () => {
        if (gifNumber > 0) {
            setGifNumber(gifNumber-1)
        }
        
    }
    const nextClick = () => {
        if (gifNumber < gifArr.length -1) {
            setGifNumber(gifNumber+1)
        }
        else {
            setGifNumber(0)
            onClose()
        }
        
    }

    const handleLoadedMetadata = (video) => {
        setVideoDuration(video.duration);
    };

    const handleTimeUpdate = (event) => {
        const video = event.target;
        const currentTime = video.currentTime;
        if (videoDuration > 0) {
            requestAnimationFrame(() => {
                const newProgress = progress.map((_, index) => {
                    if (index < gifNumber) return 100;
                    if (index === gifNumber) return (currentTime / videoDuration) * 100;
                    return 0;
                });
                setProgress(newProgress);
            });
        }
    };

    const handleVideoEnded = () => {
        // Заполняем все индикаторы по завершению видео
        setProgress(Array(5).fill(100));
    };

    useEffect(() => {
        setProgress(Array(5).fill(0));
    }, [gifNumber]);

    // useEffect(() => {
    //     setVideoSrc(videosArr[gifNumber]);
    //     if (gifNumber < 0 || gifNumber >= timeArr.length) return;

    //     const duration = timeArr[gifNumber] * 1000; // переводим в миллисекунды
    //     setProgress([0,0,0,0,0]);
    //     setIsAnimating(true);

    //     const interval = setInterval(() => {
    //         setProgress(prev => {
    //             const newProgress = [...prev];
    //             const increment = (100 / (duration / 100)); // сколько процентов добавлять
                
    //             if (newProgress[gifNumber] >= 100) {
                     
    //                 setIsAnimating(false); // Останавливаем анимацию
    //                 return [0,0,0,0,0]; // Возвращаем старое состояние
    //             }

    //             newProgress[gifNumber] = Math.min(newProgress[gifNumber] + increment, 100);
    //             return newProgress; // обновляем только текущую полоску
    //         });
    //     }, 100);

    //     return () => clearInterval(interval); // очищаем интервал при размонтировании
    // }, [gifNumber]);


    if (!isOpen) {
        return null
    }
    return (
        <div className="onboarding-container">
            <div className="onboarding-content">
            <div className="gif-indicator">
            {Array.from({ length: 5 }, (_, index) => (
                    <React.Fragment key={index}>
                        {index !== gifNumber ? (
                            <div className={`gif-indicator-item ${gifNumber > index ? "preview" : ""}`}></div>
                        ) : (
                            <div className={`gif-indicator-item`} style={{ position: "relative" }}>
                                <span className="onboarding-animated-indicator" style={{
                                    width: `${progress[index]}%`,
                                    transition: 'width 0.3s ease-out'
                                }}></span>
                            </div>
                        )}
                    </React.Fragment>
                ))}
        </div>
            <div className="gif-content">
                <p className="gif-label">{gifLabels[gifNumber]}</p>

                <VideoComponent onTimeUpdate={handleTimeUpdate} onEnded={handleVideoEnded} key={gifNumber} src={videosArr[gifNumber]} isPlaying={isPlaying} onLoadedMetadata={handleLoadedMetadata} />

                
            </div>
            </div>

            <div className="btn onboarding-skip-btn" onClick={() => {
                onClose()
                if (gifNumber === gifArr.length - 1) {
                    setGifNumber(0)
                }
            }}>{gifNumber === gifArr.length - 1 ? "Close" : "Skip"}</div>

            <div className="onboarding-arrows-box">
                {gifNumber > 0 ? (
                    <img src={whiteArrowBack} alt="Previous slide" onClick={backClick} className="onboarding-arrow-back no-select"/>
                )
                : (
                    <div>
                    </div>
                )

                }

                {gifNumber < gifArr.length ? (
                    <img src={whiteArrowNext} alt="Next slide" onClick={nextClick} className="onboarding-arrow-next no-select" />
                )
                : (
                    <div>
                    </div>
                )

                }
                
                
            </div>
        </div>
    )

    // return (
    //     <div className="onboarding-container">
    //         <div className="onboarding-content">
    //         <div className="gif-indicator">
    //         {Array.from({ length: 5 }, (_, index) => (
    //             <>
    //             {index !== gifNumber ? (
    //                 <div
    //                 key={index}
    //                 className={`gif-indicator-item ${gifNumber > index ? "preview" : ""}`}
    //             ></div>

    //             ) : (
    //                 <div 
    //                     key={index}
    //                     className={`gif-indicator-item`}
    //                     style={{position: "relative"}}
    //                 >
    //                     <span className="onboarding-animated-indicator" style={{
    //                         width: `${progress[index]}%`,
    //                     transition: `width 0.1s linear`


    //                     }}
                        
                        
    //                     >

    //                     </span>
    //                 </div>
    //             )

    //             }
 

    //             </>
    //         ))}
    //     </div>
    //         <div className="gif-content">
    //             <p className="gif-label">{gifLabels[gifNumber]}</p>
    //             <img src={gifArr[gifNumber]} className="onboarding-image" key={`gif-${gifArr[gifNumber]}-${Date.now()}`} />
    //         </div>
    //         </div>

    //         <div className="btn onboarding-skip-btn" onClick={() => {
    //             onClose()
    //             if (gifNumber === gifArr.length - 1) {
    //                 setGifNumber(0)
    //             }
    //         }}>{gifNumber === gifArr.length - 1 ? "Close" : "Skip"}</div>

    //         <div className="onboarding-arrows-box">
    //             {gifNumber > 0 ? (
    //                 <img src={whiteArrowBack} onClick={backClick} className="onboarding-arrow-back no-select"/>
    //             )
    //             : (
    //                 <div>
    //                 </div>
    //             )

    //             }

    //             {gifNumber < gifArr.length ? (
    //                 <img src={whiteArrowNext} onClick={nextClick} className="onboarding-arrow-next no-select" />
    //             )
    //             : (
    //                 <div>
    //                 </div>
    //             )

    //             }
                
                
    //         </div>
    //     </div>
    // )
}