import { useEffect, useRef, useState } from "react";
import { getReferralInfo } from "../../utils/generalConnections";
import referralCheckIcon from "../../img/copyCheck.svg"

const closeKeyboard = (ref) => {
    if (ref.current) {
        ref.current.blur(); // Убирает фокус с текстового поля
    }
};

export const AccountInputField = ({value, setValue, readOnly, handleInputEnd, handleReferralInputBlur, referralCheck}) => {

    console.log("value", value)
    const ref = useRef()

    const defaultText = readOnly ? "" : "Code"
   
    const [timeoutId, setTimeoutId] = useState(null)

   const isPlusVisible = !(value || readOnly)

    const [plusVisible, setPlusVisible] = useState(isPlusVisible)
    const [mainButton, setMainButton] = useState(null)
    
    const text = value ? value : defaultText
    const [inputValue, setInputValue] = useState(text)



    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            var MainButton = window.Telegram.WebApp.MainButton
            if (MainButton) {
                setMainButton(MainButton)
                MainButton.text = "Apply"
                MainButton.onClick(function() {
                    closeKeyboard(ref)
                    MainButton.hide()
                  });
            }
        }
    }, [])


    useEffect(() => {
        if (value) {
            setInputValue(value);
        } 
    }, [value]);

    useEffect(() => {
        if (value || readOnly) {
            setPlusVisible(false)
        } 
    }, [value, readOnly]);

    const onFocus = (e) => {
        if (readOnly) {
            return
        }
        if (mainButton) {
            mainButton.show()
        }
        handleAppearanceKeyboard()

        setPlusVisible(false)
        if (e.target.value === defaultText && value !== defaultText) {
            setInputValue("")
        }
        
    }

    const onChange = (e) => {
        if (readOnly) {
            return
        }
        setValue(e.target.value)
        setInputValue(e.target.value)

        if (timeoutId) {
            clearTimeout(timeoutId);
          }
      
      
          const id = setTimeout(async () => {
            await handleInputEnd(e.target.value);
          }, 300);
          setTimeoutId(id)
        
    }

    

    const onBlur = (e) => {
        if (readOnly) {
            return
        }
        if (mainButton) {
            mainButton.hide()
        }
        const if_referrer_code = handleReferralInputBlur()
        if (e.target.value === "" || !if_referrer_code) {
            setInputValue(defaultText)
            setPlusVisible(true)
        }      
        
        
        
    }

    //divRef.current.style.background = 'red';

    const handleAppearanceKeyboard = () => {
        if (readOnly) return
        if (!ref || !ref.current) return
       
        const currentKeyboardHeight = 205
        
        const scroll = () => {
            const { top } = ref.current.getBoundingClientRect();
            const scrollOffset = top + window.scrollY - currentKeyboardHeight;
 
            window.scrollTo({
                top: scrollOffset,
                behavior: 'smooth',
            });
        };

        requestAnimationFrame(() => {
            setTimeout(scroll, 400);
        });

      };

      const handlePlusClick = () => {
        setPlusVisible(false)
        if (ref && ref.current) {
            ref.current.focus()
        }
      }

    return (
        <>
            <div className={`input-field-container `} style={{marginBottom: "13px"}}>
            <label className="referral-item-label">My refferer</label>
               
                <div className="input-content">
                
                    {plusVisible && (
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handlePlusClick} className="plus-svg">
                            <path d="M7.25 0V14.5" stroke="#737E87" strokeWidth="2"/>
                            <path d="M14.5 7.25L-4.76837e-07 7.25" stroke="#737E87" strokeWidth="2"/>
                            </svg>
                            )

                        }
                    <input 
                        name="My referrer"
                        value={inputValue} 
                        ref={ref} 
                        className={`input-field ${(!plusVisible && !readOnly) ? "active-field" : ""}`}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        readOnly={readOnly}
                    />

                    {referralCheck() && (
                        <img src={referralCheckIcon} class="referral-copy-img" alt="copy referral link"></img>
                    )

                    }
                    
                 </div>
                
            </div>
        </>
    )
}
