import { useEffect, useState } from "react"
import { InputField } from "../General/InputField"
import { LoadImage } from "../General/LoadImage"
import { WarningModal } from "../General/ModalWindow"
import crossIcon from "../../img/crossIcon.svg"
import tonToUSD from "../../utils/tonToUSD"
import { newGetTransactionStatus, updateCollection, addTransaction, createTransaction, getTransactionStatus, addTransactionCommon } from "../../utils/generalConnections"

import TonWeb from "tonweb"




export const CollectionEditComponent = ({isEditOpen, setEditOpen, initialCollectionName, initialSelectedFileUrl, checkCollectionNameValidation, addPopupMessage, removePopupMessage, popupMessagesMap, collectionNameErrorMessage, isTest, setInitialSelectedFileUrl, setInitialCollectionName, initialCollectionLogoName, setInitialCollectionLogoName, telegramId, tonConnectUI, collectionGeneralName }) => {
        
   // console.log("initialCollectionName initialCollectionLogoName", initialCollectionName,  initialCollectionLogoName)
    const [editingCollectionName, setEditingCollectionName] = useState(initialCollectionName)
    const [editingSelectedFileUrl, setEditingSelectedFileUrl] = useState(initialSelectedFileUrl)
    const [editingSelectedFileName, setEditingSelectedFileName] = useState(initialCollectionLogoName)
    const comission = 0.01
   const [comissionToUSD, setComissionToUSD] = useState(null)

    const handleOverlayClick = (event) => {
        // Проверяем, был ли клик по оверлею
        if (event.target === event.currentTarget) {
            onClose()
        }
      };


    const onClose = () => {
        setEditOpen(false)
        setEditingCollectionName(initialCollectionName)
        setEditingSelectedFileUrl(initialSelectedFileUrl)
        setEditingSelectedFileName(initialCollectionLogoName)
    }


      
    const [isOpenWarningModal, setOpenWarningModal] = useState(false)
    const collectionNameDefaultText = "Enter a name"

    const checkValidation = () => {
        if (!editingSelectedFileUrl || !editingCollectionName) {
            return false
        }
        
        return true
    }

 
    const handleAddTransactionCommon = async (transaction_hash) => {
        const wallet_address = tonConnectUI.account.address
        const transaction_id = await addTransactionCommon(transaction_hash, comission, wallet_address)
        if (transaction_id) {
            return transaction_id
        }
        
        return null
    }

    const handleCreateTransaction = async () => {
        try {
           
            const transaction = await createTransaction(comission)
            
            const response = await tonConnectUI.sendTransaction(transaction);
            if (!response || !response.boc) {
                throw new Error("Не удалось получить ответ от блока");
            }
            
            const boc_cell = await TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(response.boc)).hash();  
           
            const transaction_hash = TonWeb.utils.bytesToBase64(boc_cell);
           
            addPopupMessage("transaction", "Transaction in progress...", "loading")
            const transaction_id = await handleAddTransactionCommon(transaction_hash)
            
            if (!transaction_id) {
                addPopupMessage("transaction", "There was an error processing the transaction. Please try again", "error")           
                return null
            }
            return transaction_id
        } catch (error) {
            addPopupMessage("transaction", "There was an error processing the transaction. Please try again", "error")           
           
            return null
        }


    }  
    
    const waitForTransactionSuccess = (transaction_id) => {
        return new Promise((resolve, reject) => {
            const id = setInterval(() => {
                newGetTransactionStatus(transaction_id).then(status => {
                    if (status === "success") {
                        clearInterval(id);
                        resolve("success");
                    } else if (status === "error") {
                        clearInterval(id);
                        addPopupMessage("transaction", "Transaction failed", "error")
                        reject(new Error("Transaction failed"));
                    }
                });
            }, 5000);
        });
    }


    const handleTransaction = async () => {
        try {
            
       
            const transaction_id = await handleCreateTransaction()
            
            if (!transaction_id) {     
                return
            }
           
           
            //console.log("sendDataToServer transaction_id", transaction_id)
            if (transaction_id) {
                 
                const status = await waitForTransactionSuccess(transaction_id);
               
                if (status === "success") {
                    
                    await handleUpdateCollection()
                    removePopupMessage("transaction")
                    return
                    
                } else {
                    console.log("Transaction did not complete successfully.");
                    return
                }
            } else {
                console.log("Failed to create transaction.");
                return
            }
        } catch (error) {
            addPopupMessage("transaction", "Transaction in progress...", "loading")
            console.log("handleCreateTransaction error", error)
        }
    }


    useEffect(() => {
        const getComissionToUSD = async () => {

            const response = await tonToUSD(comission);
                if (response) {
                    setComissionToUSD(response)
                }
                else {
                    setComissionToUSD(0)
                }
        } 

        getComissionToUSD()
    }, []);

  
    const handleUpdateCollection = async () => {
        const is_update = await updateCollection(telegramId, collectionGeneralName, editingCollectionName, editingSelectedFileUrl, editingSelectedFileName)
        if (is_update) {
            setInitialCollectionName(editingCollectionName)
            setInitialSelectedFileUrl(editingSelectedFileUrl)
            setInitialCollectionLogoName(editingSelectedFileName)
            setEditOpen(false)
            addPopupMessage("update", "Collection updated", "success")
        }
        else {
            addPopupMessage("update", "Error updating collection data!", "error")
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        if (!checkValidation()) {
            addPopupMessage("validation", "Validation error!", "error")
            return
        }
        removePopupMessage("validation")

        if (isTest) {
            await handleUpdateCollection()
        }
        else {
            await handleTransaction()
        }
        

        
    }

    if (!isEditOpen) {
        return
    }


    return (
        <>
             <div className="modal-overlay" style={{zIndex: 100}} onClick={handleOverlayClick}>
                <div className="modal-window collection-edit-modal">
                    <form className="collection-edit-container" onSubmit={handleFormSubmit}>
                        <img src={crossIcon} className="collection-edit-close-icon" onClick={onClose} width={16} height={16} />
                        <WarningModal isModalOpen={isOpenWarningModal} setIsModalOpen={setOpenWarningModal} /> 
                        <div style={{marginBottom: "16px"}}>
                        <label className="images-container-item-label" >Collection logo</label>
                        <LoadImage selectedFileUrl={editingSelectedFileUrl} setSelectedFileUrl={setEditingSelectedFileUrl} setImageName={setEditingSelectedFileName} readOnly={false} addError={addPopupMessage} removeError={removePopupMessage} errorsMap={popupMessagesMap} imgWidth={175} imgHeight={175} alt={"Collection logo"} id={"collection_logo"} />

                        </div>
                        <div style={{width: "100%", textAlign: "left", marginBottom: "43px"}}>
                            <InputField label={"Collection name"} name={"collection_name"} value={editingCollectionName} setValue={setEditingCollectionName} checkValidation={checkCollectionNameValidation} readOnly={false} required={true} maxLength={16} defaultText={collectionNameDefaultText} errorMessage={collectionNameErrorMessage} />
                        </div>
                        <div className="comission-box">
                            <div className="comission-box-label">comission</div>
                            <div>
                                <p className="comission-ton">{comission} TON</p>
                                <p className="comission-usd">&asymp;$ {comissionToUSD}</p>
                            </div>
                        </div>
                        {checkValidation() ? (
                            <input type="submit" className={`btn edit-collection-btn no-select`} value="Change" />
                        ) : (
                            <div className={`btn edit-collection-btn no-select readonly-btn`}>Change</div>
                        )                      

                        }
                        
                        


                    </form>
                </div>
            </div>
           
        </>
    )
}