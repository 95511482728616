import axios from "../axios";
export const getStatisticsData = async (telegram_id, username) => {
    const sending_json = {
        telegram_id: telegram_id,
        username: username
    }
    try {
        const {data} = await axios.post(`/api/get_statistics/`, sending_json)
        if (data.status === "success") {
            return data.statistics.events_info
        }
        return null
    } catch (error) {
        console.log(error)
        //return placeholder;
        //return avatarPlaceholder;
        return null
    }
}