import axios from "../axios";
import { isImageBlob } from "./minterUtils";
import placeholder from "../img/imagePlaceholder.png"

export const sendMinterData = async (sendingData) => {
    try {
        const response = await axios.post('/api/create_event/ ', sendingData)
        if (response.data && response.data.event_id && response.data.link_hashes) {
            return response.data
        }
        return null        

    } catch (error) {
        console.log(error)
        //return "UQCTVvPCU8fQ_g5Epu1_eRvf6I2izy9UWSpYbSuh6Yy5aQML";
        return null

    }
    
    
}



export const sendDataToBot = async (sendingData) => {

    try {
        const response = await axios.post('/api/make_post/ ', sendingData)
        if (response.data.status === "success") {
            return true
        }
        return false

    } catch (error) {
        console.log(error)
        return false

    }
    
    
}



export const getComission = async (collectionData) => {
    try {
        const {data} = await axios.post('/api/get_price/', collectionData)
        if (data.price) {
                return Number(data.price)
        }

    } catch (error) {
        console.log(error)
        return null;

    }
}

export const getRandomImage = async () => {
    try {
        const response = await axios.get('/api/random_nft/', { responseType: 'blob' });
        const base64 = await blobToBase64(response.data)
        return base64
        
 
        // if (!data.description) {
        //     const url = URL.createObjectURL(response.data);
        //    console.log()
        //     return url
        // }
        // else {
        //     console.log(data.description)
        //     return null
        // }

    } catch (error) {
        console.log(error)
        return null        
        // const blob = await fetchImageAsBlob(randomImagePlaceholder)
        // console.log(isImageBlob(blob))
        // const url = URL.createObjectURL(blob);
        // return url

    }
}

async function fetchImageAsBlob(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

async function blobToBase64(blob) {
    // Создаем FileReader для чтения Blob
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data); // Вернуть строку base64
        };
        reader.onerror = reject; // Обрабатывать ошибки
        reader.readAsDataURL(blob); // Читаем Blob и преобразуем в Data URL
    });
}



export const getEventInfoForMinter = async (event_hash) => {
    if (!event_hash) {
        return null
    }
    console.log("getParams event_id", event_hash)
    try {
        const req = {
            event_hash: event_hash
        }
        const response = await axios.post(`/api/event_info/`, req)
        const data = response.data.event_info
        var channels_array = null
        if (typeof data.subscriptions === "string") {
            channels_array = data.subscriptions.split(',').filter(item => item)
        }
        else {
            if (Array.isArray(data.subscriptions)) {
                channels_array = data.subscriptions
            }            
            
        }

        // setCount(data.nfts_cnt)
        //         setEventName(data.event_name)
        //         setName(data.collection_name)
        //         setDescription(data.description)
        //         setSelectedCollectionImageUrl(data.collection_logo_url)
        //         setSelectedEventImageUrl(data.event_logo_url)
        //         setStart(data.start_date)
        //         setEnd(data.end_date)   
        //         setTimeZone(data.user_timezone) 
        return {
            start_date: data.start_date,
            end_date: data.end_date,
            event_name: data.event_name,
            subscriptions: channels_array,
            nfts_cnt: data.nfts_cnt,
            logo: data.logo_url,
            description: data.description,
            collection_name: data.collection_name,
            collection_image_name: data.collection_image_name,
            user_timezone: data.user_timezone,
            collection_logo_url: data.collection_logo_url,
            event_logo_url: data.event_logo_url,
            empty_password: data.empty_password,
            user_msg: data.user_msg
        }
    } catch (error) {
        console.log(error)

        return null
        //setEventStatus("Error sending request")

   

       
        
    }
}
