import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const WarningModal = ({isModalOpen, setIsModalOpen, navigate }) => {

    console.log("WarningModal isModalOpen", isModalOpen)
    console.log("navigate", navigate)
    
    const onConfirm = () => {      
       
        navigate(-1)
        setIsModalOpen(false)
      };

      const handleOverlayClick = (event) => {
        // Проверяем, был ли клик по оверлею
        if (event.target === event.currentTarget) {
         setIsModalOpen(false)
        }
      };


    return (
        <>
        {isModalOpen && (
            <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-window">
                <p className="modal-message">if you leave this page, the entered data will not be saved</p>
                <div className="modal-buttons-container">                
                <button className="modal-button" onClick={() => setIsModalOpen(false)}>Stay</button>
                <button className="modal-button leave" onClick={onConfirm} >Leave</button>
                </div>
            </div>
            </div>
            )        
        }
        </>
      );
}

export const WarningModalForBackButton = ({isModalOpen, setIsModalOpen }) => {

  console.log("WarningModal isModalOpen", isModalOpen)
  const navigate = useNavigate()
  const onConfirm = () => {      
     
      navigate(-1)
      setIsModalOpen(false)
    };

    const handleOverlayClick = (event) => {
      // Проверяем, был ли клик по оверлею
      if (event.target === event.currentTarget) {
       setIsModalOpen(false)
      }
    };


  return (
      <>
      {isModalOpen && (
          <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-window">
              <p className="modal-message">if you leave this page, the entered data will not be saved</p>
              <div className="modal-buttons-container">                
              <button className="modal-button" onClick={() => setIsModalOpen(false)}>Stay</button>
              <button className="modal-button leave" onClick={onConfirm} >Leave</button>
              </div>
          </div>
          </div>
          )        
      }
      </>
    );
}


export const WarningModalForPath = ({isModalOpen, setIsModalOpen, path }) => {

  console.log("WarningModal isModalOpen", isModalOpen)
  const navigate = useNavigate()
  const onConfirm = () => {      
     
      navigate(path)
      setIsModalOpen(false)
    };

    const handleOverlayClick = (event) => {
      // Проверяем, был ли клик по оверлею
      if (event.target === event.currentTarget) {
       setIsModalOpen(false)
      }
    };


  return (
      <>
      {isModalOpen && (
          <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-window">
              <p className="modal-message">if you leave this page, the entered data will not be saved</p>
              <div className="modal-buttons-container">                
              <button className="modal-button" onClick={() => setIsModalOpen(false)}>Stay</button>
              <button className="modal-button leave" onClick={onConfirm} >Leave</button>
              </div>
          </div>
          </div>
          )        
      }
      </>
    );
}

export const LeaveCheckerWindow = ({isOpen}) => {

  console.log("isOpen", isOpen)

  
  const onClose = () => {        
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    }
    else {
      window.close();
    }
  };


  return (
    <>
      
    {isOpen && (

      <div className="modal-overlay blurred">
      <div className="modal-window">
          <p className="modal-message">Congratulations, the NFT has been sent to your wallet!</p>            
          <button className="modal-button" onClick={onClose} >Close</button>            
      </div>
    </div>

    )}  


    
    </>
  );
}



export const ShowedWarningModalWindow = ({isOpen, setIsOpen, isConfirm, handleSubmit}) => {
  
  console.log("isConfirm", isConfirm)
    if (!isOpen) {
      return
    }

    const handleConfirm = async (e) => {
      setIsOpen(false)
      await handleSubmit(e)
    }


    return (
      <>
        <div className="modal-overlay">
      <div className="modal-window">
          <p className="modal-message">You can't change collection name later</p>            
          <div className="modal-buttons-container">    
            <button className="modal-button" onClick={handleConfirm} >OK</button> 
            <button className="modal-button" onClick={() => setIsOpen(false)} >Cancel</button> 
          </div>        
      </div>
    </div>
      </>
    )
}

export const ModalWindow = ({isOpen, message, onClick}) => {

 

  return (
    <>
      
    {isOpen && (

      <div className="modal-overlay blurred">
      <div className="modal-window">
          <p className="modal-message">{message}</p>            
          <button className="modal-button" onClick={onClick} >Close</button>            
      </div>
    </div>

    )}  


    
    </>
  );

}

export const ComingSoonWindow = ({isOpen, setIsOpen}) => {

  return (
    <>
      
    {isOpen && (

      <div className="modal-overlay">
      <div className="modal-window" style={{width: "70%"}}>
          <p className="modal-message">Coming soon</p>            
          <button className="modal-button" onClick={() => setIsOpen(false)} >Close</button>            
      </div>
    </div>

    )}  


    
    </>
  )
}

const onClose = () => {        
  if (window.Telegram && window.Telegram.WebApp) {
    window.Telegram.WebApp.close();
  }
  else {
    window.close();
  }
};

export const MakePostResultWindow = ({isOpen}) => {

 
  

  if (!isOpen) {
    return
  }

  return (
    <>
      <ModalWindow isOpen={isOpen} message={"The post with the giveaway has been successfully created!"} onClick={onClose} />
    </>
  )

}

export const TimeEndOverlay = ({timeEnd}) => {
  if (!timeEnd) {
      return null
  }
  return (
    <div className="overlay-window">
      <p className="overlay-window-message">this event is over</p>
    </div>
  );
};


export const TimeZoneModal = ({timeZoneFormatted, timezone, setTimezone, isOpen, setIsOpen}) => {

  const numbers = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  
  const [currentValue, setCurrentValue] = useState(timezone)
 
  
  const handleConfirm = () => {
    
    setTimezone(currentValue);
    setIsOpen(false); // Закрыть селектор после выбора
};

const handleChange = (num) => {
  setCurrentValue(num);
};
 
const handleClose = () => {
  setCurrentValue(timezone)
  setIsOpen(false)
} 

  if (!isOpen) {
    return
  }

  return (
    <div className="modal-overlay">
      <div className="modal-window">
      <p className="modal-message timezone-label">Select time zone</p>
        <div className="options-flex"> 
            <ul className="options-list number-select">
                {numbers.map((num) => (
                    <li
                        key={num}
                        className={`number-selector-option ${num === currentValue ? "active" : ""}`}
                        onClick={() => handleChange(num)}
                    >
                        {timeZoneFormatted(num)}
                    </li>
                ))}
            </ul>
          </div>
          <div className="modal-buttons-container">    
          <button className="modal-button" onClick={handleConfirm} >OK</button> 
          <button className="modal-button" onClick={handleClose} >Cancel</button> 
          </div>
      </div>
    </div>

  )
  

}