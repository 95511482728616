import { useEffect, useRef, useState } from "react";
import { createNumbersArr } from "../../utils/appUtils";
import timezoneIcon from "../../img/timeZoneIcon.svg"
import { TimeZoneModal } from "../General/ModalWindow";

export function NumberSelector({ setInvite, defaultValue }) {
    const [number, setNumber] = useState(defaultValue);
    const [isOpen, setIsOpen] = useState(false); // Состояние для управления открытым состоянием селектора
    const numbers = Array.from({ length: 32 }, (_, i) => i + 1); // Создаем массив от 1 до 32
   
    const handleChange = (num) => {
        setNumber(num);
        setInvite(num);
        setIsOpen(false); // Закрыть селектор после выбора
    };

    return (
        <div className="form-item margin-bottom-32">
            <label className="form-item-label">Invite</label>
            <div
                className="form-input with-square number-selector margin-bottom-17"
                onClick={() => setIsOpen(!isOpen)}
            >
                {number} {/* Показываем текущее выбранное значение */}
            </div>
            {isOpen && (
                <div style={{display: 'flex'}}> 
                <ul className="options-list number-select">
                    {numbers.map((num) => (
                        <li
                            key={num}
                            className="number-selector-option"
                            onClick={() => handleChange(num)}
                        >
                            {num}
                        </li>
                    ))}
                </ul>
                </div>
            )}
        </div>
    );
}

export const TimeZoneSelector = ({timeZone, setTimeZone, readOnly}) => {
    const [isOpen, setIsOpen] = useState(false);
    
   
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Запретить прокрутку
        } else {
            document.body.style.overflow = 'unset'; // Включить прокрутку
        }

        // Возврат функции для очистки эффекта
        return () => {
            document.body.style.overflow = 'unset'; // Очистить стиль при размонтировании
        };
    }, [isOpen]);

    const numberToStr = (number) => {
        return number < 0 ? number.toString() : number === 0 ? "" : `+${number}`
    }
    const timeZoneFormatted = (number) => {
        const number_str = numberToStr(number)
        const msk_diff_str = numberToStr(number-3)
        return `UTC${number_str} (МСК${msk_diff_str})`
    }
   

    return (

        <>
        <div className={`time-field no-select ${readOnly ? "datetime-readonly-input" : ""}`} onClick={
                    () => {
                        if (readOnly) {
                            return
                        }
                        setIsOpen(true)
                        
                        }
                    }>
            <img src={timezoneIcon} className={`time-field-icon`} />
            <span className={`time-field-value`}> {timeZone !== null ? timeZoneFormatted(timeZone) : "Time zone"}</span>       
          </div>
         
          {!readOnly && (
               <TimeZoneModal timeZoneFormatted={timeZoneFormatted} timezone={timeZone} setTimezone={setTimeZone} isOpen={isOpen} setIsOpen={setIsOpen}/>
            )}
        </>
    );
}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  export const CalendarSelector = ({ type, values_arr, value, setValue }) => {
    const listRef = useRef();
    const [displayedValues, setDisplayedValues] = useState([...values_arr]);
    const init_index = values_arr.includes(value) ? values_arr.indexOf(value) : values_arr.length - 1
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState(init_index);
 

    useEffect(() => {
        setDisplayedValues([...values_arr]); // Обновляем отображаемые значения при изменении входящего массива
        if (type === "day") {
            if (!values_arr.includes(value)) {
                setValue(values_arr[values_arr.length - 1]); // Установить последнее значение массива
            }
        }
        const initialIndex = values_arr.indexOf(value);
        if (initialIndex !== -1) {
          setCurrentSelectedIndex(initialIndex);
        }

        console.log("initialIndex", initialIndex)
    }, [values_arr]);

    useEffect(() => {
        const scrollToSelectedValue = () => {
          if (listRef.current) {
            const selectedItem = listRef.current.children[currentSelectedIndex];
    
            if (selectedItem) {
              selectedItem.focus();
              selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
          }
        };
    
        const timeoutId = setTimeout(scrollToSelectedValue, 0);
        return () => clearTimeout(timeoutId); // Очистка задержки при размонтировании
      }, [currentSelectedIndex]);

    const valueFormatted = (num) => {
        switch (type) {
            case "day":
            case "year":
            case "hours":
                return String(num);
            case "month":
                return months[num - 1];
            case "minutes":
                return String(num).padStart(2, '0');
            default:
                return String(num);
        }
    };

    const handleClick = (num) => {
        setValue(num); // Устанавливаем значение при клике
        
    };

  


    return (
        <div className={`calendar-selector-container selector-${type}` } >
            <ul className="calendar-options-list" ref={listRef} 
            // onWheel={handleWheel}
            // onTouchStart={handleTouchStart}
            >
                {displayedValues.map((num, index) => (
                    <li
                        key={index}
                        tabIndex={0} 
                        className={`calendar-selector-option ${num === value ? 'selected' : ''}`}
                        onClick={() => handleClick(num)}
                    >
                        {valueFormatted(num)}
                    </li>
                ))}
            </ul>
        </div>
    );
};
//<span style={{width: "40px", height: "40px", border: "2px solid blue", position: "absolute", top: "40px", left: "0"}}></span>